import { createEvent, createStore } from 'effector';
const DEFAULT_STATE = {
    errors: null,
    showRules: false,
};
const $state = createStore(DEFAULT_STATE);
const setState = createEvent();
const clear = createEvent();
$state.on(setState, (prevState, newState) => ({ ...prevState, ...newState }));
$state.reset(clear);
export const footerModel = {
    $state,
    setState,
    clear,
};
