import mobileWebpX3 from './icon@3x.webp';
import mobileWebpX2 from './icon@2x.webp';
import mobileWebpX1 from './icon.webp';
import mobilePngX3 from './icon@3x.png';
import mobilePngX2 from './icon@2x.png';
import mobilePngX1 from './icon.png';
import { nextImgToSource } from '@common/helpers/next-img-to-source';
export const IMAGE = [
    {
        images: [mobileWebpX3, mobileWebpX2, mobileWebpX1, mobilePngX3, mobilePngX2, mobilePngX1],
    },
].reduce((result, imagesSet) => {
    const { images } = imagesSet;
    images.forEach((nextImg) => {
        result.push(nextImgToSource(nextImg));
    });
    return result;
}, []);
