import React, { useState } from 'react';
import { ButtonGroup } from '@vkontakte/vkui';
import { createAnalyticClickAttribute } from '@analytics';
import { intl } from '@common/shared/lib/intl';
import { modalManagerModel } from '@common/services/modal-manager';
import { showSubscriptionCancelReasonModal } from '@common/entities/subscriptions';
import { applyOffer } from '@common/components/Root/actions';
import Typograf from '@common/components/common/Typograf';
import ModalButton from '@common/components/modals/ModalButton';
import { $offersSuccess } from '../../../model/offers';
import { analyticsDictionary } from '../../analytics-dictionary';
import { WinbackIcon } from './fc-icons';
import { showWinbackCancelSubscription as messages } from './messages';
const Actions = (props) => {
    const { subscriptionId } = props;
    const [loading, setLoading] = useState(false);
    return (<ButtonGroup mode='vertical' gap='m' stretched={true}>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalCancelSubscriptionWinbackButtonConfirmClick)} size={'l'} stretched={true} loading={loading} disabled={loading} onClick={async () => {
            setLoading(true);
            await applyOffer({
                correlationId: $offersSuccess.getState()?.correlationId || '',
                button: {
                    type: 'purchase',
                },
            });
        }}>
        {intl.formatMessage(messages.cancelButton)}
      </ModalButton>
      <ModalButton {...createAnalyticClickAttribute(analyticsDictionary.modalCancelSubscriptionWinbackButtonCancelClick)} size={'l'} stretched={true} mode={'secondary'} onClick={() => {
            showSubscriptionCancelReasonModal({ subscriptionId });
        }}>
        {intl.formatMessage(messages.confirmButton)}
      </ModalButton>
    </ButtonGroup>);
};
const getPayloadToManagerModal = (subscriptionId) => {
    const price = $offersSuccess.getState()?.with.price || '';
    return {
        analyticShowAttribute: analyticsDictionary.modalCancelSubscriptionWinbackShow,
        icon: <WinbackIcon />,
        header: intl.formatMessage(messages.header, { price }),
        subheader: <Typograf>{intl.formatMessage(messages.subheader)}</Typograf>,
        actions: <Actions subscriptionId={subscriptionId}/>,
    };
};
export const showWinbackCancelSubscription = (subscriptionId) => modalManagerModel.showModal(getPayloadToManagerModal(subscriptionId));
