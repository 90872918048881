import classNames from 'classnames';
import { Button } from '@vkontakte/vkui';
import styles from './styles.module.scss';
const ApplyOfferButton = (props) => {
    const { className, children, ...restProps } = props;
    return (<Button type={'button'} className={classNames(styles.button, className)} {...restProps}>
      {children}
    </Button>);
};
export default ApplyOfferButton;
