import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { useUnit } from 'effector-react';
import { ContentLayout } from '@website/shared/ui/layouts/content-layout';
import { headerModel } from '@website/entities';
import { MenuTypeSelector, SubMenu } from './ui';
import styles from './style.module.scss';
import { MainLayout } from '@website/shared/ui/layouts/main-layout';
import { Logo } from './ui/logo';
const Header = (props) => {
    const { showLogo = true, transparentBackgroundColor = false, className, linksClass = '', mode = 'floating', wideContainer, } = props;
    const isTransparentBackgroundColorModel = useUnit(headerModel.$isTransparentBackgroundColor);
    const blur = useUnit(headerModel.$blur);
    const setShowLogo = useUnit(headerModel.setShowLogo);
    const setIsTransparentBackgroundColor = useUnit(headerModel.setIsTransparentBackgroundColor);
    const resetAllHeaderModel = useUnit(headerModel.resetAllHeaderModel);
    const headerClassNames = useMemo(() => {
        return classNames(styles.header, {
            [styles.header_transparent]: transparentBackgroundColor || isTransparentBackgroundColorModel,
            [styles.header_blur]: blur,
            [styles.header__mode_fixed]: mode === 'fixed',
            [styles.header__mode_floating]: mode === 'floating',
        }, className);
    }, [transparentBackgroundColor, isTransparentBackgroundColorModel, blur, mode]);
    const LayoutClassNames = useMemo(() => {
        return classNames(styles.header__layout, {
            [styles.header__layout_wide]: wideContainer,
        }, className);
    }, [wideContainer, className]);
    useEffect(() => {
        if (showLogo) {
            setShowLogo(showLogo);
        }
        if (transparentBackgroundColor) {
            setIsTransparentBackgroundColor(transparentBackgroundColor);
        }
        return () => {
            resetAllHeaderModel();
        };
    }, []);
    return (<MainLayout.Header className={headerClassNames}>
      <ContentLayout className={LayoutClassNames}>
        <div className={styles.headerWrapper}>
          <Logo showLogo={showLogo}/>
          <MenuTypeSelector linksClass={linksClass}/>
        </div>
        <SubMenu />
      </ContentLayout>
    </MainLayout.Header>);
};
export default Header;
