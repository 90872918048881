import { useUnit } from 'effector-react';
import { Icon24MenuOutline } from '@vkontakte/icons';
import { headerModel } from '@website/entities';
import styles from './styles.module.scss';
export const burgerMenuId = 'header__burger_menu';
const BurgerMenu = () => {
    const toggleOpen = useUnit(headerModel.toggleOpenSubMenu);
    return (<div className={styles.burgerIcon} id={burgerMenuId}>
      <Icon24MenuOutline onClick={toggleOpen}/>
    </div>);
};
export default BurgerMenu;
