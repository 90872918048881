import { AuthButton } from '@website/features/user';
import { AuthChecker } from '@common/entities/auth-state';
import { MenuUserCard } from '@website/widgets/header/ui/menu-user-card';
import { Navigation } from '@website/widgets/header/ui/navigation';
import styles from './styles.module.scss';
const InlineMenu = ({ linksClass }) => {
    return (<div className={styles.inlineMenu}>
      <Navigation linksClass={linksClass}/>
      <AuthChecker authRender={<MenuUserCard />} unAuthRender={<AuthButton>Войти в личный кабинет</AuthButton>} fallback={<div className={styles.fallback}/>}/>
    </div>);
};
export default InlineMenu;
