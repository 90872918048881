import { modalManagerModel } from '@common/services/modal-manager';
import React from 'react';
import { analyticsDictionary } from '@analytics';
import { Content } from './ui/content';
import { Header } from './ui/header';
import { Footer } from './ui/footer';
const payloadToManagerModal = (props) => {
    return {
        analyticShowAttribute: analyticsDictionary.modalPaymethodCancelReason,
        header: <Header />,
        children: <Content />,
        footer: <Footer successHandler={props?.successHandler}/>,
    };
};
export const showUnlinkCardReasonModal = (props) => modalManagerModel.showModalPageWithFixedFooter(payloadToManagerModal(props));
export default showUnlinkCardReasonModal;
