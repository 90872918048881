import React from 'react';
import { modalManagerModel } from '@common/services/modal-manager';
import { CheckoutModalV3Children } from './checkout-modal-v3-children';
import styles from './styles.module.scss';
export const showCheckoutModalV3 = (params) => {
    modalManagerModel.showModalPageWithFullHeight({
        analyticShowAttribute: '',
        size: 520,
        className: styles.checkoutModal,
        children: <CheckoutModalV3Children {...params}/>,
        dynamicContentHeight: true,
    });
};
