import { memo, useEffect, useMemo } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import classNames from 'classnames';
import { redirectToVKID } from '@common/entities/auth-vkid';
import { AuthButton } from '@website/features/user';
import { AuthChecker } from '@common/entities/auth-state';
import { Navigation } from '@website/widgets/header/ui/navigation';
import { AccountMenu } from './ui';
import Avatar from './images/avatar.svg';
import Exit from './images/exit.svg';
import styles from './styles.module.scss';
import { PROFILE_ROUTE_LIST } from '@website/shared/lib/profile-routes';
import { ROUTES } from '@website/shared/lib/routes';
import { useUnit } from 'effector-react';
import { SubscriptionsModel } from '@common/entities/subscriptions';
import { ContentLayout } from '@website/shared/ui/layouts/content-layout';
import { menuUserCardId } from '@website/widgets/header/ui/menu-user-card/menu-user-card';
import { burgerMenuId } from '@website/widgets/header/ui/burger-menu/burger-menu';
import { headerModel } from '@website/entities';
import { useMatchMedia } from '@common/shared/hooks';
const subMenuId = 'header__submenu';
const SubMenu = memo(() => {
    const isProfileAvailable = useUnit(SubscriptionsModel.$userHasSubscriptionWithPermissionGoToLk);
    const closeSubMenu = useUnit(headerModel.closeSubMenu);
    const openSubMenu = useUnit(headerModel.$openSubMenu);
    const paddingOff = useMatchMedia('(min-width: 1024px)');
    const openSubMenuClassName = useMemo(() => openSubMenu && styles.submenuContainer__opened, [openSubMenu]);
    const { pathname } = useRouter();
    const logout = () => {
        redirectToVKID();
    };
    const isProfileLink = PROFILE_ROUTE_LIST.some((route) => route === pathname);
    const authRender = (<button type={'button'} className={classNames(styles.subMenu__link, styles.subMenu__logout)} onClick={logout}>
      <Exit />
      <span>Сменить пользователя</span>
    </button>);
    const personalAccount = (<div className={classNames(styles.subMenu__link, {
            [styles.current]: isProfileLink,
        })}>
      <Avatar />
      {isProfileLink ? <span>Личный кабинет</span> : <Link href={ROUTES.profile}>Личный кабинет</Link>}
    </div>);
    useEffect(() => {
        const click = (ev) => {
            const clickInsideSubMenu = Boolean(ev.target.closest(`#${subMenuId}`));
            const clickInsideMenuUserCard = Boolean(ev.target.closest(`#${menuUserCardId}`));
            const clickInsideBurgerMenu = Boolean(ev.target.closest(`#${burgerMenuId}`));
            if (!(clickInsideSubMenu || clickInsideMenuUserCard || clickInsideBurgerMenu)) {
                closeSubMenu();
            }
        };
        document.addEventListener('click', click);
        return () => {
            document.removeEventListener('click', click);
        };
    }, []);
    return (<div className={classNames(styles.submenuContainer, openSubMenuClassName)}>
      <ContentLayout offPadding={paddingOff} className={styles.contentLayout}>
        <div className={styles.submenuContainer__content} id={subMenuId}>
          <div className={styles.subMenu}>
            <AuthChecker authRender={<AccountMenu />} unAuthRender={null}/>
            <AuthChecker authRender={isProfileAvailable && personalAccount} unAuthRender={null}/>
            <Navigation mode={'mobile'} className={styles.subMenu__navigation}/>
            <AuthChecker authRender={authRender} unAuthRender={<AuthButton>Войти через VK ID</AuthButton>}/>
          </div>
        </div>
      </ContentLayout>
    </div>);
});
SubMenu.displayName = 'SubMenu';
export default SubMenu;
