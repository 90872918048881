import Router from 'next/router';
import { redirectToVKIDWithCheckDeepLink } from '@common/entities/auth-vkid/model/redirect-auth';
import { ApplyOfferModel } from '../model';
import { AuthStateModel } from '@common/entities/auth-state';
import { SubscriptionsModel } from '@common/entities/subscriptions';
import { ROUTES } from '@website/shared/lib/routes';
import { applyOffer as applyOfferCommon } from '@common/components/Root/actions/applyOffer';
const getTypeFlowInDependingOfButtonType = (type) => {
    const map = {
        student_form: 'student',
        group_subscription: 'group_subscription',
    };
    return map[type] ?? 'applyOffer';
};
export const applyOfferWebsiteFactory = (factoryParams = { skipCheckSubscription: false }) => async (applyOfferParamsCommon) => {
    const { skipCheckSubscription = false } = factoryParams;
    const { correlationId, offerName, button, subscriptionType } = applyOfferParamsCommon;
    const isAuthorized = AuthStateModel.$isAuthorized.getState();
    const hasActiveSubscriptionByStatus = SubscriptionsModel.$hasActiveSubscriptionStatus.getState();
    const hasActiveSubscriptionByList = SubscriptionsModel.$hasActiveSubscriptionByList.getState();
    if (!isAuthorized) {
        const flow = getTypeFlowInDependingOfButtonType(button.type);
        if (flow === 'applyPromocode' || flow === 'validatePhone') {
            return;
        }
        const params = {
            correlationId,
            subscriptionType,
        };
        let state;
        if (flow === 'group_subscription') {
            state = {
                flow,
                params: {
                    correlationId,
                    offerName: offerName ?? '',
                },
            };
        }
        else {
            state = {
                flow,
                params,
            };
        }
        await redirectToVKIDWithCheckDeepLink(state);
        return;
    }
    if (!skipCheckSubscription) {
        if (hasActiveSubscriptionByStatus || hasActiveSubscriptionByList) {
            void Router.push(ROUTES.profile);
            return;
        }
    }
    ApplyOfferModel.setInProgress(correlationId);
    await applyOfferCommon({
        correlationId,
        button,
        callbackAfterFinishedFlow: () => ApplyOfferModel.setReady(),
        offerName,
    });
};
