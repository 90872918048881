import React, { useEffect, useMemo, useState } from 'react';
import { createAnalyticAttrs, useAnalyticShowDirect } from '@analytics';
import { useUnit } from 'effector-react';
import { useGetVariantInDependOnVkUIPlatform } from '@common/shared/hooks';
import { VkuiPlatformChooser } from '@common/shared/ui/vkui-platform-chooser';
import { createFilePreview } from '@common/shared/lib';
import apiCommon from '@common/utils/ApiCommon';
import { studentAnalytics, StudentModel } from '@common/entities/student';
import { ImagePreview } from '../ImagePreview';
import { NextStepButton } from '../next-step-button';
import { Banner } from '../banner';
import { DesktopLayout, PhoneLayout, UploadDocumentButton } from './ui';
import { mapStudentStatusDocumentTypeToAnalytics, } from './lib';
import styles from './styles.module.scss';
import { modalStateModel, nextStepButtonModel, uploadDocumentModel, } from '@common/entities/student/lib/show-student-form-modal/models';
const UploadDocumentTemplate = (props) => {
    const { icon, messages, documentType } = props;
    const [error, setError] = useState();
    const studentStatus = useUnit(StudentModel.$status);
    const { title, subtitle, uploadButtonTitle } = useGetVariantInDependOnVkUIPlatform(messages);
    const analytics = studentAnalytics.form[mapStudentStatusDocumentTypeToAnalytics(documentType)];
    const modalState = useUnit({ ...modalStateModel });
    const nextStepButtonState = useUnit({ ...nextStepButtonModel });
    const { $state: { [documentType]: document }, addDocument, deleteDocument, } = useUnit({ ...uploadDocumentModel });
    const documentPreview = useMemo(() => {
        return createFilePreview(document.file);
    }, [document]);
    const documentHandleChange = (event) => {
        const file = event.target?.files?.[0] ?? null;
        addDocument({ documentType, payload: { file } });
        setError(null);
        modalState.setState({ status: 'idle' });
    };
    const onClear = () => {
        deleteDocument(documentType);
        modalState.setState({ status: 'idle' });
    };
    const sendDocument = async () => {
        if (document?.wasSuccessSendDocument) {
            return;
        }
        if (!document?.file) {
            return;
        }
        const response = await apiCommon.sendStudentFormImage({
            image: document?.file,
            type: documentType,
        });
        const { data } = response;
        if (!data.successful) {
            const { errors } = data;
            const DEFAULT_ERROR = { detail: 'Не удалось загрузить файл' };
            const error = errors[0] ?? DEFAULT_ERROR;
            setError(error);
            return Promise.reject(error);
        }
    };
    useEffect(() => {
        nextStepButtonState.setState({
            click: async () => {
                if (!documentPreview) {
                    return;
                }
                await sendDocument();
                addDocument({ documentType, payload: { wasSuccessSendDocument: true } });
            },
            analytic: analytics.click(studentStatus),
            status: 'idle',
        });
        nextStepButtonState.setState({ status: documentPreview === null ? 'error' : 'idle' });
    }, [documentPreview]);
    useEffect(() => {
        if (error) {
            onClear();
        }
    }, [error]);
    useAnalyticShowDirect(analytics.show(studentStatus));
    const iconDiv = <div className={styles.icon}>{icon}</div>;
    const uploadButton = (<UploadDocumentButton documentFileHandleChange={documentHandleChange} uploadButtonTitle={uploadButtonTitle}/>);
    const phoneLayoutUploadButton = documentPreview ? (<div className={styles.nextStepButton}>
      <NextStepButton />
    </div>) : (uploadButton);
    const banner = error && (<Banner className={styles.bannerBlock} header={error.title} subheader={error.detail} {...createAnalyticAttrs(analytics.errorBannerShow(studentStatus, error?.code), ['show'])}/>);
    const imagePreview = documentPreview && (<ImagePreview imageUrl={documentPreview} onClear={onClear}/>);
    const layoutProps = {
        icon: iconDiv,
        title,
        subtitle,
        banner,
        preview: imagePreview,
    };
    return (<VkuiPlatformChooser desktop={<DesktopLayout {...layoutProps} button={uploadButton} documentFilePreview={documentPreview}/>} mobile={<PhoneLayout {...layoutProps} button={phoneLayoutUploadButton}/>}/>);
};
export default UploadDocumentTemplate;
